import React, { useState, useEffect } from "react"
import styled from "styled-components"

import { getCourseById ,getGeneralInfo, getCoursesPage} from "../../providers"

import { MainLayout } from "../../components/Layouts/MainLayout"
import { Hero3 } from "../../components/Hero3/index"
import { HeaderMenu } from "../../components/Courses/HeaderMenu"
import { MenuTabs } from "../../components/Courses/MenuTabs"
import { Programming } from "../../components/Courses/Programming"
import { Instructors } from "../../components/Courses/Instructors"
import { ContactForm } from "../../components/Courses/ContactForm"
import { Achivements } from "../../components/Courses/Achivements"
import { Testimonies} from "../../components/Courses/Testimonies"
import { Whatsapp} from "../../components/Courses/Whatsapp"


import NotFoundPage from "../404.js"

import "../../assets/css/global.css"

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100%;
`

const CuoursePage = props => {
  const { id } = props.params
  
 // let id = 1
  const [course, setCourse] = useState({})
  const [info, setInfo] = useState({})
  const [coursefound, setCoursefound] = useState(true)

  const [avaCourse, setAvaCourse] = useState({})
  const url = "https://api.itcadperu.com.pe/"

  useEffect(() => {
    const fetchCourse = async () => {
      try {

        /*
        switch (courseurl) {
          case "Especialista Modelador BIM":
             id = 1
             break;
          case "Especialista Infraestructuras Civil 3D":
             id = 2
             break;
          case "Autodesk Infraworks":
             id = 3
             break;
          case "Modelamiento y Visualización Arquitectónica":
             id = 4
             break;
          case "Autodesk Robot Structural":
             id = 5
             break;
          case "Autodesk Inventor":
             id = 6
             break;
          case "Autodesk Dynamo":
             id = 7
             break;
          case "Especialista CAD":
             id = 8
             break;
          case "Especialista Coordinador BIM":
             id = 9   
             break;
          default:
            id=10
        }*/

       
        const _course = await getCourseById(id)
        setCourse(_course)
        const _info = await getGeneralInfo()
      
        setInfo(_info)

        
        const _avaCourse = await getCoursesPage()
        setAvaCourse(_avaCourse[0])
        
      
        
      } catch (error) {
        console.log(error)
    
      }
    }
    fetchCourse()
    /*
    if (id > 9 || isNaN(id)) {
      setCoursefound(false)
    }*/

  }, [id])


  //if (coursefound) {
   
  return (
    <>
      <MainLayout>
        <Container>
          <Hero3 data={course}  />
          <HeaderMenu children={course?.title} autodesk={course?.is_autodesk_course}/>
          <MenuTabs data={course} info={info[0]?.whatsapp_link} />
          <Programming data={course} />
          <Instructors data={course?.instructors} />
          <Achivements data={course?.achievements} banner={course?.banner_image} link={course?.banner_image_link} />
          <Testimonies data={course?.course_testimonies} testimony={course} imgt={course?.testimony_section_image} />
          <Whatsapp data={info}></Whatsapp>
         {/* <ContactForm />*/}
        </Container>
      </MainLayout>
    </>
  )
 
 
   
}

export default CuoursePage

