import React from "react"
import styled from "styled-components"

import { dateFormat } from "../../utils"

import { Title } from "../Title"
import { Table } from "antd"

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background: #0044B0;
 // margin-top: 4em;
  
  @media (max-width: 500px) {
    margin-top: 3em;
  }
`
const FirstBlock=styled.div`
  position: relative;
  width: 100%;
  max-width: 1440px;
  background-color: #0044B0;
  display:flex;
  justify-self: center;
  padding: 50px;
  flex-direction: column;

    .table {
    margin-top: 25px;
    overflow: none;
    max-width: 1400px;
    width: 100%;
    border-left: 2px solid #FFFFFF;


    .ant-table-tbody > tr.ant-table-row:hover > td{
      background: #0044B0 !important; ;
    }

    .ant-table-thead th {
      background: #FFFFFF;
        color: #0044B0;
        border-left: 3px solid #0044B0;
        left: -3px;
    }
    .ant-table-cell {

      font-size: 16px;
      line-height: 160%;
      background: #0044B0;
        color:#FFFFFF;
        border: 2px solid #FFFFFF;
        left: -1.5px;
    }
    .ant-pagination {
      display: none;
    }
    .table::selection{
      background-color: #60BF94;
    }
  }
  @media (max-width: 780px) {
    width:100% ;
    overflow: scroll;
    padding: 20px 0px 30px 30px;
    .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
    padding: 8px 10px;
}
.ant-table-cell {
  font-size: 14px;
}
    }
`

const Header2= styled.div`
  display: flex;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 1.7em;
  color: #60BF94;
`
export const Programming = ({ data }) => {


  const dataSource= data.programs?.map((item)=>{
  return {
      key: item.id,
      date: dateFormat(item.init_date),
      days: item.days,
      shedule: item.schedule,
      modality:
        item.modality?.charAt(0) + item.modality?.slice(1).toLowerCase(),
      duration: item.duration + " " + "horas",
      instructor:
        `${item.instructor.name} ${item.instructor.last_name}`,
    }

  
})



  const columns = [
    {
      title: "Fecha de inicio",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Días",
      dataIndex: "days",
      key: "days",
    },
    {
      title: "Horarios",
      dataIndex: "shedule",
      key: "shedule",
    },
    {
      title: "Modalidad",
      dataIndex: "modality",
      key: "modality",
    },
    {
      title: "Duración",
      dataIndex: "duration",
      key: "duration",
    },
    {
      title: "Instructor",
      dataIndex: "instructor",
      key: "instructor",
    },
  ]
  return (
    <Container>
      <FirstBlock>
      <Header2>Programación</Header2>
      <Table className="table" dataSource={dataSource} columns={columns} />
      </FirstBlock>
    </Container>
  )
}
