import * as React from "react"
import styled from "styled-components"
import { Button03 } from "./Button03"

const Container = styled.div`
  margin-top: 2.2rem;
  display: flex;
  /*gap: 20px;
  width: 50%;*/
  max-width: 1440px;
  width: 100%;
  .image {
    width: 20%;
    display: relative;
    align-items: center;
    img {
      width: 180px;
      height: 180px;
      border-radius: 50%;
      border: 10px solid #0044B0;
      box-shadow: 0px 1px 7px 1px rgba(0, 0, 0, 0.15);
      border-style: double;
      object-fit: cover;
    }
  }
  .content {
    width: 25%;
    margin-top: 1em;
    .btn {
      margin-top: 16px;
    }
  }
  .phrase{
    width: 45%;
    margin: 0 0 0 6%;
    font-size: 30px;
    font-weight: 700;
    font-style: italic;
    text-align: center;
  .author{
    font-size: 21px;
    font-weight: 400;
  }
  }

  @media (max-width: 900px) {
    width: 100%;
    flex-direction: column;
    margin-top: 3rem;

    .image,
    .content {
      justify-content:center;
      width: 100%;
      text-align: center;
    }
    .phrase{
      width: 100%;
      font-size: 22px;
      margin: 1.5em 0 0 0;
      text-align: right;
      .author{
    font-size: 16px;
  }
    }
  }


`
const Title = styled.h2`
  font-size: 24px;
  line-height: 30px;
`
export const CardAvatar = ({ data }) => {
  
  return (
    <Container>
     
        <div className="image">
        <img src={data.image} alt="cursos bim para personas que desean aprender"/>
        </div>
  
      <div className="content">
        <Title>
          <b>
            {`${data.name} ${data.last_name}`}
          </b>
        </Title>
        <div>{data.ocupation}</div>
        <div>Codigo: {data.code}</div>
        <div className="btn">
          <a href={data.cv} target="_blank">
            <Button03>Conoce su CV</Button03>
          </a>
         
        </div>
        {data.url_demo_class && <div className="btn">
        <a href={data.url_demo_class} target="_blank">
            <Button03>Clase demostrativa</Button03>
          </a>
        </div>}
      </div>

      <div className="phrase">{data?.phrase}
      <div className="author"> {data?.phrase && `- ${data.name} ${data.last_name}`}</div>
      </div>
    </Container>
  )
}
