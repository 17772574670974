import React from "react"
import { Tabs } from "antd"
import styled from "styled-components"

import { ContentTab } from "./ContentTab"

import "antd/dist/antd.css"

const Container = styled.div`
  width: 100%;
  height: auto;
  /*display: flex;
  justify-content: flex-end;
  padding-left: 150px;*/
  margin: 0.625rem auto 0;
  max-width: 80rem;
  padding: 0 3.125em;
  position: relative;

  .ant-tabs-tab {
    font-weight: 700;
    font-size: 16px;
    line-height: 160%;
    color: #292929;
      }
      .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #0044B0;
    text-shadow: 0 0 0.25px currentColor;
}

.ant-tabs-ink-bar {
    position: absolute;
    background: #0044B0;
    pointer-events: none;
}
.ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-bottom > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before, .ant-tabs-bottom > div > .ant-tabs-nav::before {
    position: absolute;
    right: 0;
    left: 0;
    border-bottom: 0px solid #f0f0f0;
    ;
}


@media (max-width: 500px) {
  padding: 0 2em ;
  display: flex;
  .ant-tabs-tab {
    display:flex;
    flex-wrap: wrap;
  }

  .ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 28px;
}
}

`

export const MenuTabs = ({ data, info }) => {
  const objetive = {
    objective: data?.objective,
    img: data?.objective_image,
    content: data?.content,
    imgdescription: data?.objective_image_description,
  }

  const addresTo = {
    name: data?.addressed_to,
    img: data?.adressed_to_image,
    imgdescription: data?.adressed_to_image_description,
  }
  const certificates = {
    name: data?.certificates,
    img: data?.certificate_image,
    imgdescription: data?.certificate_image_description,
  }

  const { TabPane } = Tabs

  return (
    <Container>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Objetivo" key="1">
          <ContentTab typeContent="key1" data={objetive} info={info}/>
        </TabPane>
        <TabPane tab="Dirigido a" key="2">
          <ContentTab typeContent="key2" data={addresTo} info={info}/>
        </TabPane>
        <TabPane tab="Certificados" key="3">
          <ContentTab typeContent="key3" data={certificates} info={info}/>
        </TabPane>
      </Tabs>
    </Container>
  )
}
