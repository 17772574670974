import React, { useState } from "react"
import styled from "styled-components"
import { Button } from "../Button"
import { Title } from "../Title"
import { useForm } from "react-hook-form"
import "antd/dist/antd.css"
import { message } from "antd"

const key = "updatable"
const Container = styled.form`
  width: 100%;
  height: 100%;
  max-width: 80rem;
  margin: 0 auto 3.4375rem;
  padding: 0 3.125em;

  .btn {
    margin-top: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
`
const ContentForm = styled.div`
  margin-top: 2.375rem;
  width: 100%;
  display: flex;
  gap: 200px;

  .text {
    width: 50%;
    div {
      margin-bottom: 20px;
    }
    label {
      display: block;
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      margin-bottom: 8px;
      color: #3374ba;
    }
    .input-error {
      border-color: #ff4d4f;
    }
    span {
      color: #ff4d4f;
    }
    input,
    select {
      width: 100%;
      height: 36px;
      background: #FFFFFF;
      border: 1px solid #d9d9d9;
      box-sizing: border-box;
      border-radius: 2px;
    }
    input {
      padding: 0.5em;
    }
    select {
      padding-left: 0.5em;
    }
  }
  @media (max-width: 900px) {
    flex-direction: column;
    gap: 10px;
    .text {
      width: 100%;
    }
  }
`
export const ContactForm = () => {
  const [messageAlert, setMessageAlert] = useState("")

  // const openMessage = () => {
  //   message.loading({ content: "Enviando", key })
  //   setTimeout(() => {
  //     message.success({ content: { messageAlert }, key, duration: 2 })
  //   }, 1000)
  // }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const onSubmit = (element, e) => {
    var xhr = new XMLHttpRequest()
    var url =
      "https://api.hsforms.com/submissions/v3/integration/submit/20146505/e496b98d-4390-46b1-966b-9989d23e9f6a"
    var data = {
      fields: [
        {
          name: "firstname",
          value: element.firstName,
        },
        {
          name: "lastname",
          value: element.lastName,
        },
        {
          name: "mobilephone",
          value: element.phone,
        },
        {
          name: "email",
          value: element.email,
        },
        {
          name: "country",
          value: element.country,
        },
        {
          name: "city",
          value: element.city,
        },
        {
          name: "curso",
          value: element.course,
        },
        {
          name: "acepta_que_lo_contactemos_por_whatsapp",
          value: element.whatsapp,
        },
      ],
      context: {
        pageUri: "www.ITCAD.pe/",
        pageName: "Pruebas paper cube",
      },
    }
    var final_data = JSON.stringify(data)
    xhr.open("POST", url)
    xhr.setRequestHeader("Content-Type", "application/json")
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        // setMessageAlert(xhr.responseText) // Returns a 200 response
        //alert(xhr.responseText)
        message.loading({ content: "Enviando", key })
        setTimeout(() => {
          message.success({
            content: "Recibimos tus datos con exito",
            key,
            duration: 2,
          })
          e.target.reset()
          window.location.replace("")
        }, 1000)
      } else if (xhr.readyState === 4 && xhr.status === 400) {
        //alert(xhr.responseText) // Returns a 400 error the submission is rejected.
        message.loading({ content: "Enviando", key })
        setTimeout(() => {
          message.error({
            content: "Ocurrio un error",
            key,
            duration: 2,
          })
        }, 1000)
      } else if (xhr.readyState === 4 && xhr.status === 403) {
        //alert(xhr.responseText) // Returns a 403 error if the portal isn't allowed to post submissions.
        message.loading({ content: "Enviando", key })
        setTimeout(() => {
          message.error({
            content: "Ocurrio un error",
            key,
            duration: 2,
          })
        }, 1000)
      } else if (xhr.readyState === 4 && xhr.status === 404) {
        //alert(xhr.responseText) //Returns a 404 error if the formGuid isn't found
        message.loading({ content: "Enviando", key })
        setTimeout(() => {
          message.error({
            content: "Ocurrio un error",
            key,
            duration: 2,
          })
        }, 1000)
      }
    }
    xhr.send(final_data)
  }

  return (
    <Container id="form" onSubmit={handleSubmit(onSubmit)}>
      <Title className="blue">Reserva tu matrícula</Title>
      <ContentForm>
        <div className="text">
          <div>
            <label for="firstName">Nombre</label>
            <input
              type="text"
              id="firstName"
              {...register("firstName", {
                required: true,
                maxLength: 20,
                minLength: 5,
              })}
              className={errors.firstName ? "input-error" : ""}
            />
            <span>{errors.firstName && "este campo es requerido "}</span>
          </div>
          <div>
            <label for="lastName">Apellido</label>
            <input
              type="text"
              id="lastName"
              {...register("lastName", {
                required: true,
                maxLength: 20,
                minLength: 5,
              })}
              className={errors.lastName ? "input-error" : ""}
            />
            <span>{errors.lastName && "este campo es requerido "}</span>
          </div>
          <div>
            <label for="phone">Número de Telefono</label>
            <input
              type="text"
              id="phone"
              maxLength="9"
              {...register("phone", {
                required: true,
                maxLength: 9,
                pattern: /[0-9]{9}/,
              })}
              className={errors.phone ? "input-error" : ""}
            />
            <span>{errors.phone && "ingrese un número de 9 digitos"}</span>
          </div>
          <div>
            <label for="email">Correo Electrónico</label>
            <input
              type="email"
              id="email"
              {...register("email", {
                required: true,
                pattern: /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
              })}
              className={errors.email ? "input-error" : ""}
            />
            <span>{errors.email && "este campo es requerido "}</span>
          </div>
        </div>
        <div className="text">
          <div>
            <label for="country">Pais</label>
            <input
              type="text"
              id="country"
              {...register("country", {
                required: true,
              })}
              className={errors.country ? "input-error" : ""}
            />
            <span>{errors.country && "este campo es requerido "}</span>
          </div>
          <div>
            <label for="city">Ciudad</label>
            <input
              type="text"
              id="city"
              {...register("city", {
                required: true,
              })}
              className={errors.city ? "input-error" : ""}
            />
            <span>{errors.city && "este campo es requerido "}</span>
          </div>
          <div>
            <label for="course">Curso</label>
            <input
              type="text"
              id="course"
              {...register("course", {
                required: true,
              })}
              className={errors.course ? "input-error" : ""}
            />
            <span>{errors.course && "este campo es requerido "}</span>
          </div>
          <div>
            <label for="whatsapp">Acepta que lo contactemos por whatsapp</label>
            <select
              id="whatsapp"
              {...register("whatsapp", {
                required: true,
              })}
              className={errors.whatsapp ? "input-error" : ""}
            >
              <option value="Si">Si</option>
              <option value="No">No</option>
            </select>

            <span>{errors.whatsapp && "este campo es requerido "}</span>
          </div>
        </div>
      </ContentForm>
      <div className="btn">
        <Button>Enviar</Button>
      </div>
    </Container>
  )
}
