import * as React from "react"
import styled from "styled-components"
import { Paragraph } from "../Paragraph"
import { Title } from "../Title"
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const First = styled.div`
  width: 100%;
  /*min-height: 300px;*/
  height: auto;
  margin: 1.5rem 0 2.5rem;
  display: flex;
  flex-direction: row;
  position: relative;
  .title{
    font-size:23px;
    font-weight: 700;
    color:#0044B0;
    padding-left: 80px;
    margin-top: 2em;
  }
  .carrusel{
  .react-multiple-carousel__arrow {
      z-index: 1;
    }
    .react-multiple-carousel__arrow::before {
      color:#0044B0;
      font-size: 30px;
    }
    .react-multiple-carousel__arrow {
      background:none;
    }
    .react-multi-carousel-item {
    padding: 0 90px;
    }
    .cqsxfz{
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  }
  .left{
    width: 50%;
    align-self: flex-start;
    padding: 0 0 0 5em;
  }
  .right{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-self: right;
    position: relative;
    img{
      width: 100%;
      object-fit: cover;
      //aspect-ratio: 2/1;
      max-height:400px
    }
    .description{
      margin-top: 1em;
      font-style: italic;
      color: #7D7D7D;
      font-size: 18px;
    }
  }

  @media (max-width:1200px) {
    .left{
      padding: 0 0 0 1em;

    }
  }


  @media (max-width:900px) {
    flex-direction: column;
    margin: 0;

    .title{
      padding-left: 0;
      display: flex;
      justify-content: center;
    }
    .left{
      width: 100%;
      padding: 0;

    }
    .right{
      width: 100%;
      align-items: center;
      padding: 0 3em;
      img{
        margin-top: 0.5em;
      }

      .description{
      align-self: flex-start;
    }
    }
  }
  @media (max-width:600px) {
    .right{
      img{
        width: 100%;
      }
      .description{
        font-size: 14px;

      }
    }
  }

`

const ParagraphStyled = styled(Paragraph)`
  line-height: 175%;
  text-align: center;
  .testimonio{
    font-size: 18px;
  }
  .nombre{
    font-weight: 700;
    text-align: center;

  }

  @media (max-width:600px) {
    .testimonio{
    font-size: 15px;
  }
  }
`
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};


export const Testimonies = ({ data, testimony,imgt }) => {

  {/*const onChange = (currentSlide) => {
   
  };
console.table('ant',imgt)
*/}

  return (
    <Container>
      <First>
      {data && 
              <div className="left">
                      <div className="title">Testimonios del Curso</div>
                      
                      <Carousel
                      className="carrusel"  
                          responsive={responsive}
                          ssr={true}
                          infinite={true}
                          autoPlay={true}
                          autoPlaySpeed={5000}
                          keyBoardControl={true}
                          transitionDuration={500}
                          containerClass="carousel-container"
                          >
                          {data?.map(slider => (
                                    <ParagraphStyled>
                                      <div className="testimonio">{slider?.testimony}</div>
                                      <div className="nombre">{slider?.name} {slider?.occupation && `- ${slider?.occupation}`}</div>
                                    </ParagraphStyled>

                          ))}
                      </Carousel>
                      
              </div> }
              {imgt && 
              <div className="right">
                      <img src={imgt}/>
                      <div className="description">{testimony?.testimony_section_image_description}</div>
              </div>}
    </First>
    </Container>
  )
}

{/*     <Carousel autoplaySpeed={5000} afterChange={onChange}>
          {data?.map(slider => (
            <ParagraphStyled>
          {/*{element?.image &&  <img src={url + element?.image} alt="person-galery"/>}
              <img src="/images/Courses/img-1- 1.png"/>
  
  <div className="testimonio">{slider?.testimony}</div>
  <div className="nombre">{slider?.name}</div>
  <div className="ocupacion">{slider?.occupation}</div>

            </ParagraphStyled>
          ))}
        </Carousel> 
      
  <div>Item 1</div>
  <div>Item 2</div>
  <div>Item 3</div>
  <div>Item 4</div>
*/}