import * as React from "react"
import styled from "styled-components"

const ContentButton = styled.button`
  width: ${p =>
    p.type === "large" ? "315px" : p.type === "small" ? "112px" : "209px"};
  height: ${p =>
    p.type === "large" ? "48px" : p.type === "small" ? "36px" : "48px"};
  background-color: ${p =>
    p.background === "celeste" ? "#49B3CB" : "#3374ba"};
  /* background-color: #3374ba; */
  color: #FFFFFF;
  box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  border: none;
  font-family: Montserrat;
  font-style: normal;
  line-height: 20px;
  font-weight: bold;
  font-size: 16px;
  overflow: hidden;
  transition: 0.2s transform ease-in-out;
  will-change: transform;
  z-index: 0;
  &::after {
    border-radius: 4px;
    background-color: ${p =>
      p.background === "celeste" ? "#2a6470" : "#004182"};
    /* background-color: #004182; */
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-100%, 0) rotate(10deg);
    transform-origin: top left;
    transition: 0.2s transform ease-out;
    will-change: transform;
    z-index: -1;
  }
  &:hover::after {
    transform: translate(0, 0);
  }
  &:hover {
    border: none;
    color: #FFFFFF;
    transform: scale(1.05);
    will-change: transform;
    cursor: pointer;
  }
  @media (max-width: 900px) {
    width: 219px;
  }
`
export const Button = ({ type, children, background }) => {
  return (
    <ContentButton background={background} type={type}>
      {children}
    </ContentButton>
  )
}
