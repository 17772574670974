import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { Button04 } from "../Button04"
import { Title } from "../Title"
import { Paragraph } from "../Paragraph"
import { Items } from "../Items"


const Container = styled.div`
  margin-top: 1rem;
  width: 100%;
  display: flex;
  gap: 5.625em;
margin-bottom: 2em;
  .text {
    width: 50%;
    min-height: 298px;
    position: relative;

    .header{
      font-size: 20px;
      font-weight: 700;
    }
    .btn {
      padding-top: 10px;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: auto;
      position: relative;

    }
    .content-items {
      margin-top: 25px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 15px;
      .items {
        width: 100%;
      
      }
    }
  }
  .imagen {
    width: 60%;
    display: flex;
    flex-direction: column;
    position: relative;
    right:0px;
    top:-70px;
    justify-self: right;
    img {
      width: 100%;
      height: auto;
    //justify-self:right;
    object-fit: cover;
    aspect-ratio: 2/1;

       }
    .imgdescription{
      font-style: italic;
      font-size: 16px;
      margin-top: 5px;
    }
  }
  @media (max-width: 900px) {
    flex-direction: column;
    //gap: 2em;
    margin-bottom: 0;

    .text{
      width: 100%;
      .header{
      font-size: 17px;
      font-weight: 700;
    }
      .content-items{

        .items {
        width: 100%;
      
      }
      }

    }
    .imagen {
      width: 100%;
      position: relative;
      right: 0;
      img{
        width: 100%;
        height: auto;
      }
    }
  }
`

export const ContentTab = ({ typeContent, data, info}) => {
 
  let paragraphs = [];

  if (data?.objective) {
    paragraphs = data.objective.split("/").map((paragraph, index) => (
      <p key={index}>{paragraph}</p>
    ));
  }

  let component = (
    <Container>
      <div className="text">
      <div className="header">¿Qué aprenderemos?</div>
        <Paragraph>{paragraphs}</Paragraph>
        <div className="btn">
          <a href={data.content} target="_blank">
            <Button04>Descargar brochure</Button04>
          </a>
        </div>
      </div>
      <div className="imagen">
        <img src={data?.img} alt="Certificados autodesk, cursos revit y bim 360 en lima peru"/>
        <div className="imgdescription">{data?.imgdescription}</div>
      </div>
    </Container>
  )
  switch (typeContent) {
    case "key1":
      component = (
        <Container>
          <div className="text">
            <div className="header">¿Qué aprenderemos?</div>
            <Paragraph>{paragraphs}</Paragraph>
            <div className="btn">
              <a href={data.content} target="_blank">
                <Button04>Descargar brochure</Button04>
              </a>
            </div>
          </div>
          <div className="imagen">
            <img src={data.img} alt="Capacitación bim con certificado en lima peru. Cursos ideales para ser especialista."/>
            <div className="imgdescription">{data?.imgdescription}</div>
          </div>
        </Container>
      )
      break
    case "key2":
      component = (
        <>
          <Container>
            <div className="text">
            <div className="header">¿Este curso es dirigido para ti?</div>
              <div className="content-items">
                <div className="items">
                  {data.name.map((element, index) => (
                    <Items color="#004182" key={index}>
                      {element.name}
                    </Items>
                  ))}
                  {/* TODO que reciba un array */}
                  {/* <Items color="#004182">{data.name[0].name}</Items>
                  <Items color="#004182">{data.name[1].name}</Items>
                  <Items color="#004182">{data.name[2].name}</Items>
                  <Items color="#004182">{data.name[3].name}</Items> */}
                </div>
                {/* <div className="items">
                  <Items color="#004182">{data.name[4].name}</Items>
                  <Items color="#004182">{data.name[5].name}</Items>
                  <Items color="#004182">{data.name[6].name}</Items>
                </div> */}
                
              </div>
              <div><Button04><Link style={{color: "white"}} to={info}
            target="_blank">Matrícula aquí</Link></Button04></div>
            </div>
            <div className="imagen">
              <img src={data.img} alt="donde estudiar revit. Cursos ideales para ser especialista."/>
              <div className="imgdescription">{data?.imgdescription}</div>
            </div>
          </Container>
        </>
      )
      break
    case "key3":
      component = (
        <Container>
          <div className="text">
          <div className="header">Potencia tu perfil profesional</div>
            <div className="content-items">
              <div>
                {data.name.map((element, index) => (
                  <Items color="#004182" key={index}>
                    {element.name}
                  </Items>
                ))}
              </div>
            </div>
            <div><Button04><Link style={{color: "white"}} to={info}
            target="_blank">Matrícula aquí</Link></Button04></div>
          </div>
          <div className="imagen">
            <img src={data.img} alt="Curso de civil 3d certificado para estudiantes en lima peru"/>
            <div className="imgdescription">{data?.imgdescription}</div>
          </div>
        </Container>
      )
      break
    default:
      break
  }
  return <>{component}</>
}
