import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Title } from "../Title"
import { CardAvatar } from "../CardAvatar"
import { Button04 } from "../Button04"

const Container0 = styled.div`
  width: 100%;
  /*margin: 3.125rem auto 0;*/
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const Container = styled.div`
  width: 100%;
  height: 100%;
  max-width: 80rem;
  margin: 4.125rem auto 2.625rem;
  padding: 0 3.125em;
  .content-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin: 0 3.375rem;
  }

  @media (max-width:900px){
    .content-cards{
      margin:0px;
    }
  }
  @media (max-width:500px){
    margin: 3em auto 1.5em;
    padding: 0 2.5em;


  }

`
const Cardcontainer = styled.div`
width: 100%;
font-size: 24px;
display: flex;
flex-direction: row;

@media (max-width:900px){
  flex-direction: column;
    }
`


const H1 = styled.div`
  font-weight: 700;
  font-size: 21px;
  font-feature-settings: 'clig' off, 'liga' off;
  max-width: 100%;
  margin-bottom: 10px;
  color: #0044B0;
`

const Foto = styled.img`
position: relative;
width: 100%;
aspect-ratio: 1.5;
object-fit: contain;
bottom: 0px;
max-height:360px ;
`
const Bigcontainer = styled.div`
display: flex;
flex-wrap: wrap;
justify-content: flex-start;
width: 100%;
`

const Left = styled.div`
display: flex;
flex-direction: column;

p{
  font-size: 16px;

}

a{
  margin-bottom: 1.2em;
  font-size:16px ;
}


@media (max-width:900px){
  width: 100%;
  p{
    font-size: 15px;

  }
    }

`

const Right = styled.div`
display: flex;
flex-wrap: wrap;
justify-content: space-around;
width: 100%;
gap:48px;

@media (max-width:900px){
  width: 100%;
    }
`
const SecondBlock=styled.div`
  position: relative;
  width: 100%;
  
  display:flex;
  justify-content: center;
  color: #FFFFFF;
  margin-bottom: 3em;
  overflow: hidden;

  img{
    width: 100vw;
    object-fit: cover;
    //max-height: 230px;
    max-width: 100%;
  }
  @media (max-width:1200px) {
    margin-bottom: 1em;
    max-height: 150px;
    img{
      max-height: 150px;
    }
  }
  @media (max-width:500px) {
    margin-bottom: 1em;
    max-height: 100px;
    img{
      max-height: 100px;
    }
  }
`
/*const SecondBlockChild= styled.div`
width: 1440px;
position: relative;

display: flex;
justify-self: center;
p{
  padding: 40px 0 10px 100px;
  width: 950px;
  font-size: 40px;
  font-weight: 700;
  line-height: 120%;
}
`*/

export const Achivements = ({ data, banner, link }) => {

  
  return (
    <Container0>
    <Container>
      <Bigcontainer>
       {data && 
       data?.map(element => (
          <Cardcontainer>
              <Left>
                    <H1>{element.title}</H1>
                    <p>{element.description}</p>
                    {element.pdf_file && <a href={element.pdf_file } target="_blank">
                      <Button04>Descarga aquí</Button04></a>
                    }
                    {element.video_link && <a href={element.video_link } target="_blank">
                      <Button04>Descarga aquí</Button04></a>
                    }
                    {element.image && <a href={element.image } target="_blank">
                      <Button04>Descarga aquí</Button04></a>
                    }

              </Left>

          </Cardcontainer>
        ))}
      </Bigcontainer>
    </Container>
                    {banner &&
                  <SecondBlock>
                    <a href={link} target="_blank">
                    <img src={banner}/>
                    </a>
                  </SecondBlock>}
    </Container0>
  )
}
