import * as React from "react"
import styled from "styled-components"
import { Title } from "../Title"
import { CardAvatar } from "../CardAvatar"

const Container = styled.div`
  width: 100%;
  height: 100%;
  max-width: 80rem;
  margin: 4.125rem auto 3.625rem;
  padding: 0 3.125em;
  .content-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin: 0 3.375rem;
  }

  @media (max-width:900px){
    .content-cards{
      margin:0px;
    }
  }
  @media (max-width:500px){
    margin: 3rem auto 0;

  }
`
export const Instructors = ({ data }) => {
  return (
    <Container>
      <Title className="blue">Nuestros Instructores</Title>
      <div className="content-cards">
        {data?.map(element => (
          <CardAvatar key={element.id} data={element}></CardAvatar>
        ))}
      </div>
    </Container>
  )
}
