import * as React from "react"
import styled from "styled-components"

import { Button02 } from "../Button02"



/*const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 2em 0 4em 0;
  .content-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin: 0 3.375rem;
  }

  @media (max-width:900px){

    padding: 0px 0px;
    .content-cards{
      margin:0px;
    }
  }*/


const Container= styled.div`
width: 100%;
justify-content: center;
margin: 1em 0 4em 0;

position: relative;
display:flex;
justify-self: center;
flex-direction: column;

`

const Div = styled.div`
display: flex ;
justify-content: center;
width: 100%;
a{
font-size:16px;
font-weight:700 ;
}

`
const Title = styled.h2`
position: relative;
width: 100%;
  padding: 30px 0;
  font-weight: 900;
  font-size: 2.25em;
  line-height: 2.8125rem;
  color:#0044B0;
  text-align:center;
  justify-content: center;
  margin: 0;

  @media (max-width: 900px) {
    font-size: 25px;
    padding: 20px 10px;
    line-height: 2.3rem;


    /* font-size: 38px; */
  }

`


export const Whatsapp = ({ data }) => {
 
  return (
    <Container>
        <Title>El primer paso para el éxito, está aquí</Title>
      <Div>
      <a  href={data[0]?.whatsapp_link} target="_blank"><Button02>Matrícula aquí</Button02> </a>
      </Div>
    </Container>
  )
}
